<template>
    <section class="main">
      <PageTitle :title="title" />
      <section class="dark red">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <h2>{{title}}</h2>
              <div v-html="categoryDescription"></div>
            </div>
          </div>
        </div> 
      </section>
      <ProductList :products="products" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { PageTitle, ProductList } from '@/components'

export default {
  components: {
    PageTitle,
    ProductList,
  },
  computed: {
    ...mapGetters({
      products: 'products/filteredProducts',
    }),
    title() {
      const { category } = this.$route.params
      switch (category) {
        case 'ordinarie':
          return 'Våra goda bakverk'
        case 'special':
          return 'Våra specialtårtor'
        default:
          return ''
      }
    },
    categoryDescription() {
      const { category } = this.$route.params
      switch (category) {
        case 'ordinarie':
          return `<p>Hos oss finns det massor med goda tårt- och bakverk, men också gott och nyttigt bröd.<br>Då vi använder oss av de bästa råvarorna, kan vi alltid vara säkra på att våra kunder blir nöjda.</p><p class="">Obs! detta är inte hela vårt sortiment. Vi fyller på med fler goda tårt- och bakverk allt eftersom. Allt bakas inte dagligen.<br>Har du frågor gällande det dagliga sortimentet ber vi dig kontakta oss.</p>`
        default:
          return ''
      }
    }
  },
  mounted() {
    const { category } = this.$route.params
    this.setCategory(category)
  },
  methods: {
    setCategory(category) {
      if (category) {
        this.$store.dispatch('products/setCategory', category)
      }
    }
  },
  metaInfo: {
    title: 'Tårtor, Bakelser & Bröd | Bageri & Konditori i Trollhättan | Dagmar Carlssons Hembageri Eftr. | dagmarcarlssons.se',
    description: 'Goda tårtor, bakelser & bröd finns hos oss på Dagmar Carlssons Hembageri Eftr. i Trollhättan.',
  },
  watch: {
    '$route' (to) {
      const { category } = to.params
      this.setCategory(category)
    }
  }
}
</script>

<style></style>
